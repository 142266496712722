<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Donor'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/undraw_medical_care_movn.svg"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col-md"
                v-if="imgLoaded != false"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Golongan Darah</strong></td>
                    <td>{{ data.blood_type_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Umur</strong></td>
                    <td>{{ data.age }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal Terakhir Donor</strong></td>
                    <td>{{ data.last_donation_date }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Telepon</strong></td>
                    <td>{{ data.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Kelamin</strong></td>
                    <td>{{ data.gender }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{{ data.email }}</td>
                  </tr>
                </table>
                <button
                  @click="$router.push({name: 'donor-edit', params: {id: data.id}})"
                  class="btn mx-1 btn-success"
                  v-if="btn"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('donors/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/donors')
      }
    },

    async get() {
      this.data = await module.get('donors/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/donors')
      }
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "2202"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah", route: "/blood-bank" },
      { title: "Daftar Donor", route: "/donors" },
      { title: "Detail" },
    ])

    this.get()
    this.setActiveButton()
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>